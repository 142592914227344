import { action, makeAutoObservable } from 'mobx'
import * as _ from 'lodash'

import env from '../../../../config/env'
import http from '../../../../services/http'
import qs from 'qs'
import { IConventionalOfferIsLoadedDTO, ISpreadsheetDTO, IOfferDto, IOfferParameterDto } from './types'
import IPagedRequest from '../../../../models/dto/fetch/IPagedRequest'
import IPagedResult from '../../../../models/dto/fetch/IPagedResult'
import moment from 'moment'

const { API_BASE_URL } = env

class Store {
  static readonly id: string = 'ConventionalOfferStore'
  spreadsheet!: IConventionalOfferIsLoadedDTO
  offers!: IPagedResult<IOfferDto>

  get api() {
    http.defaults.baseURL = API_BASE_URL
    return http
  }

  constructor() {
    makeAutoObservable(this)

    this.spreadsheet = {
      isLoaded: true,
    }

    this.offers = {
      totalCount: 0,
      items: [],
      isLoaded: false,
    }

    this.getSpreadsheet = this.getSpreadsheet.bind(this)

    this.getOffers = this.getOffers.bind(this)
  }

  @action.bound async getSpreadsheet(offerParamDto: IOfferParameterDto) {
    this.spreadsheet.isLoaded = false

    let params = `?orgUnitId=${offerParamDto.orgUnit!.id}`

    params += `&startDateStr=${moment(offerParamDto.startDate).format('DD-MM-YYYY')}`

    params += `&endDateStr=${moment(offerParamDto.endDate).format('DD-MM-YYYY')}`

    params += `&isItForExistingCustomer=${offerParamDto.isItForExistingCustomer}`

    if (offerParamDto.filterOffer?.id != undefined) {
      params += `&offerId=${offerParamDto.filterOffer.id}`
    }

    if (offerParamDto.templateOffer?.id != undefined) {
      params += `&templateOfferId=${offerParamDto.templateOffer.id}`
    }

    const result: ISpreadsheetDTO = await (await this.api.get(`/api/app/conventional-offer/spreadsheet${params}`)).data
    this.spreadsheet = { spreadsheet: result, isLoaded: true }
  }

  @action.bound async postSpreadsheet(dto: ISpreadsheetDTO, orgUnitId: number, startDate: Date, endDate: Date,
    isItForExistingCustomer: boolean, offerId?: number) {
    
    let params = `?orgUnitId=${orgUnitId}`

    params += `&startDateStr=${moment(startDate).format('DD-MM-YYYY')}`

    params += `&endDateStr=${moment(endDate).format('DD-MM-YYYY')}`

    params += `&isItForExistingCustomer=${isItForExistingCustomer}`
    
    params += (offerId == null ? '' : `&offerId=${offerId}`)

    const result = await this.api.post(`/api/app/conventional-offer${params}`, dto)

    return result
  }

  @action.bound unloadSpreadsheet() {
    this.spreadsheet.isLoaded = false
  }

  @action.bound async calculateCircularReferences(dto: ISpreadsheetDTO, offerParamDto: IOfferParameterDto) {
    const params = `?isItForExistingCustomer=${offerParamDto.isItForExistingCustomer}`

    const result = await this.api.post(`/api/app/conventional-offer/calculate-circular-references${params}`, dto)

    return result
  }

  @action.bound async getOffers(params: IPagedRequest) {
    this.offers.isLoaded = false
    const q = qs.stringify(params, { encodeValuesOnly: true, addQueryPrefix: true })

    const result: IPagedResult<IOfferDto> = await (await this.api.get(`/api/app/conventional-offer/offers${q}`)).data
    this.offers = { ...result, isLoaded: true }
  }

  @action.bound async getOffersAsList() {
    const result = await this.api.get(`/api/app/conventional-offer/offers-as-list`)

    return result.data
  }

  @action.bound async deleteOffer(id: number) {
    await this.api.delete(`/api/app/conventional-offer/${id}`)
    this.getOffers({ skipCount: 0, maxResultCount: 5 })
  }

  @action.bound async getSingleOffer(id: number) {
    const result = await this.api.get(`/api/app/conventional-offer/single-offer?offerId=${id}`)

    return result.data
  }

  // @action.bound async getVersionsAsList() {
  //   const result = await this.api.get(`/api/app/conventional-offer-version/versions-as-list`)

  //   return result.data
  // }

  @action.bound async getCheckForPricingInInterval(orgUnitId: number, startDateStr: string, endDateStr: string) {
    const params = `?orgUnitId=${orgUnitId}&startDateStr=${startDateStr}&endDateStr=${endDateStr}`

    const result = await this.api.get(`/api/app/conventional-offer-version/check-for-pricing-in-interval${params}`)

    return result.data
  }

  @action.bound async getOrgUnits() {
    const result = await this.api.get(`/api/app/conventional-offer-version/org-units`)

    return result.data
  }
}

export default Store
