import React, { useState, useEffect, FC, useRef, useMemo } from 'react'
import { inject, observer } from 'mobx-react'

import Store from './store'

import { Button, ConfirmModal, Loading, Table } from '@/components'
import '@progress/kendo-ui'
import $ from 'jquery'
import {
  ICircularFormulaDTO,
  ISpreadsheetDTO,
  IOfferDto,
  IOfferParameterDto,
  ISheetScrollPositionDto,
  NumberKeyNumberValueDto,
  StringKeyNumberValueDto,
} from './types'
import moment from 'moment'
import { Collapse } from 'antd'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ParameterPopup from './parameter-popup'
import 'utils/extensions'
import { IOrgUnitDto } from '../conventional-offer-admin/types'
// import { ConventionalOfferVersionDto } from '../conventional-offer-admin/types'

interface IPage {
  ConventionalOfferStore?: Store
  t: any
}

const { Panel } = Collapse

const formatTL = new Intl.NumberFormat('tr-TR')

const excelLetterArr = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]

const getExcelColLetterFromIndex = (colIdx: number) => {
  // zero based index
  var res = ''

  var quotient = Math.trunc(colIdx / 26)

  if (quotient >= 1) {
    res += getExcelColLetterFromIndex(quotient - 1)
  }

  return res + excelLetterArr[Math.trunc(colIdx % 26)]
}

// hardcoded
const totalSheetCount = 2
const summarySheetName = 'Summary'
const summarySheetIdx = 0
const personelSheetName = 'Personnel'
const personelSheetIdx = 1

let parametricSheetErrorCheckRowIdxList = [] as number[]
let personelSheetToggleHiddenRowIdxList = [] as number[]

let lastSheetName = ''
let sheetScrollPositionsArr = [] as ISheetScrollPositionDto[]

const sheetScrollingHelper = (sheetName: string) => {
  const lastSheetIdx = sheetScrollPositionsArr.findIndex((x) => x.sheetName == lastSheetName)
  const curSheetIdx = sheetScrollPositionsArr.findIndex((x) => x.sheetName == sheetName)
  if (lastSheetIdx == -1 || curSheetIdx == -1) return
  lastSheetName = sheetName
  let lastSheetScrollPositions = sheetScrollPositionsArr[lastSheetIdx]
  let curSheetScrollPositions = sheetScrollPositionsArr[curSheetIdx]
  const scrollableDiv = $('.k-spreadsheet-scroller')
  lastSheetScrollPositions.scrollLeft = scrollableDiv.scrollLeft() || 0
  lastSheetScrollPositions.scrollTop = scrollableDiv.scrollTop() || 0
  scrollableDiv.scrollLeft(curSheetScrollPositions.scrollLeft)
  scrollableDiv.scrollTop(curSheetScrollPositions.scrollTop)
}

const Page: FC<IPage> = inject('ConventionalOfferStore')(
  observer((props: IPage) => {
    const { ConventionalOfferStore: store, t } = props

    const { offerId: paramOfferId }: any = useParams()

    const { offers } = store!

    KendoLicensing.setScriptKey(
      '141j044b041h541j4i1d542e581e4i1i4g260i2k0f2e0b2f0d2j0d2k07300j1e603j09214c1g52254c2b5j30572j541c4g265c435i3b5k3f5j395f395i335h424i2h123d551g4c24511g572f562g614958315e355d33593358395748532753314e3i4i004e012b002a14613b5k1a28062c054g3a1j3c201a28014g3k5144534654414c424g375139245i033b0g1j03511j312e3123521e15412j0d5f2a4i0k3k1k4k444h3815'
    )

    const [collapseActiveKey, setCollapseActiveKey] = useState(['1'])

    const [selectedOfferRow, setSelectedOfferRow] = useState<IOfferDto>()

    const [deleteModal, setDeleteModal] = useState(false)
    const toggleDeleteModal = () => setDeleteModal(!deleteModal)

    const [showConfirmSaveModal, setShowConfirmSaveModal] = useState(false)

    const [instance, setInstance] = useState<any>(null)
    const [data, setData] = useState<any>()
    const [isLoading, setIsLoading] = useState(false)
    const [initalLoadFlag, setInitialLoadFlag] = useState(true)

    const usePrevious = (value: any) => {
      const ref = useRef()
      useEffect(() => {
        ref.current = value
      })
      return ref.current
    }

    const [savedOfferId, setSavedOfferId] = useState<number>()
    const [templateOffer, setTemplateOffer] = useState<IOfferDto>()
    const [orgUnit, setOrgUnit] = useState<IOrgUnitDto>()
    const [startDate, setStartDate] = useState<Date>()
    const [endDate, setEndDate] = useState<Date>()
    const [offerParamDto, setOfferParamDto] = useState<IOfferParameterDto>()
    const previousOfferParamDto = usePrevious(offerParamDto)

    const [showParameterPopup, setShowParameterPopup] = useState(false)
    const [isParameterPopupConfirmed, setIsParameterPopupConfirmed] = useState(false)
    // const [version, setVersion] = useState<ConventionalOfferVersionDto>()
    const [isItForExistingCustomer, setIsItForExistingCustomer] = useState(false)

    useEffect(() => {
      if (isParameterPopupConfirmed) {
        setIsParameterPopupConfirmed(false)
        store?.unloadSpreadsheet()
        setCollapseActiveKey([])
        setOfferParamDto({
          filterOffer: selectedOfferRow,
          // versionDto: { ...version },
          templateOffer: templateOffer,
          isItForExistingCustomer: isItForExistingCustomer,
          orgUnit: orgUnit,
          startDate: startDate,
          endDate: endDate
        } as IOfferParameterDto)
      }
    }, [isParameterPopupConfirmed])

    useEffect(() => {
      if (!store?.spreadsheet.isLoaded) return

      setData({ ...store?.spreadsheet.spreadsheet })
      setInstance($('#spreadsheet'))
    }, [store?.spreadsheet.isLoaded])

    useEffect(() => {
      if (!instance) return

      if (initalLoadFlag) {
        instance.kendoSpreadsheet({
          toolbar: false,
          sheets: [
            { columns: [{ width: 400 }], rows: [{ height: 20, cells: [{ value: t('Please create a new offer or select an existing offer') }] }] },
          ],
        })

        if (paramOfferId) {
          store?.getSingleOffer(Number(paramOfferId)).then((paramOffer: IOfferDto) => {
            setSelectedOfferRow(paramOffer)
            if (paramOffer.isReadOnly === true) {
              store?.unloadSpreadsheet()
              setCollapseActiveKey([])
              setOfferParamDto({
                filterOffer: paramOffer,
                // versionDto: paramOffer.versionDto,
                isItForExistingCustomer: paramOffer.isItForExistingCustomer,
                orgUnit: paramOffer.orgUnit,
                startDate: paramOffer.startDate,
                endDate: paramOffer.endDate
              } as IOfferParameterDto)
            } else {
              setShowParameterPopup(true)
            }
          })
        }
      } else {
        const spreadsheetDto = store?.spreadsheet.spreadsheet

        if (spreadsheetDto && spreadsheetDto.sheets.length > 0) {
          lastSheetName = spreadsheetDto.sheets[0].name
          sheetScrollPositionsArr = spreadsheetDto.sheets.map(
            (x) =>
              ({
                sheetName: x.name,
                scrollTop: 0,
                scrollLeft: 0,
              } as ISheetScrollPositionDto)
          )
          personelSheetToggleHiddenRowIdxList = spreadsheetDto.personelSheetToggleHiddenRowIdxList || []
          parametricSheetErrorCheckRowIdxList = spreadsheetDto.parametricSheetErrorCheckRowIdxList || []
        }

        const toolbar = {
          home: [
            'exportAs',
            spreadsheetDto?.isItReadonly === true
              ? undefined
              : {
                  type: 'button',
                  text: ' ' + t('Save'),
                  icon: 'save',
                  attributes: { class: 'btn btn-link btn-success saveSpreadsheet' },
                  click: confirmSave,
                },
          ],
          insert: false,
          data: false,
        } as kendo.ui.SpreadsheetToolbar

        const spreadsheetData = instance
          .kendoSpreadsheet({
            toolbar: toolbar,
            sheets: data.sheets,
            excelExport: function (e: any) {
              e.preventDefault()

              var spreadsheetJson = $('#spreadsheet').data('kendoSpreadsheet')?.toJSON()

              if (
                !spreadsheetJson.sheets ||
                spreadsheetJson.sheets.length != totalSheetCount ||
                spreadsheetJson.sheets[summarySheetIdx].name != t(summarySheetName) ||
                spreadsheetJson.sheets[personelSheetIdx].name != t(personelSheetName)
              ) {
                toast.error(t('Excel sheets are changed'))
                return
              }

              new kendo.ooxml.Workbook(e.workbook).toDataURLAsync().then((dataURL) => {
                const offerName = (spreadsheetJson.sheets[summarySheetIdx].rows[0].cells[1].value || '').trim()

                kendo.saveAs({
                  dataURI: dataURL,
                  fileName: (offerName == '' ? t('unnamed_offer') : offerName) + '.xlsx',
                })
              })
            },
            selectSheet: function (e: any) {
              sheetScrollingHelper(e.sheet._sheetName)
            },
            change: function (e: any) {
              let didEnterLoop = false
              e.range.forEachCell(function (row: any, column: any, cellProperties: any) {
                if (didEnterLoop) return
                didEnterLoop = true

                const activeSheet = spreadsheetData._workbook.activeSheet()
                const curSheetIdx = spreadsheetData._workbook.sheetIndex(activeSheet)
              })
            },
          })
          .data('kendoSpreadsheet')

        $('.k-spreadsheet-toolbar:eq(0)')
          .data('kendoSpreadsheetToolBar')
          .bind('click', function (ev: any) {
            // if ($(ev.target).index()) { // bu calismadi
            if ($(ev.target).index() != null && $(ev.target).index() != -1) {
              var ddl = $('.k-popup-edit-form select.k-file-format:eq(0)').data('kendoDropDownList')
              if (ddl) {
                var oldData = ddl.dataSource.data()
                ddl.dataSource.remove(oldData[oldData.length - 1])
              }
            }
          })

        for (let i = 0; i < data.sheets.length; i++) {
          const s = data.sheets[i]

          if (s) {
            if (s.hiddenRows && s.hiddenRows.length > 0) {
              s.hiddenRows.forEach((x: number) => {
                spreadsheetData._workbook._sheets[i]._rows.hide(x)
              })
            }
            if (s.hiddenColumns && s.hiddenColumns.length > 0) {
              s.hiddenColumns.forEach((x: number) => {
                spreadsheetData._workbook._sheets[i]._columns.hide(x)
              })
            }

            if (spreadsheetDto?.isItReadonly === true) {
              spreadsheetData._workbook._sheets[i].range('A1:AX200').enable(false)
            }
          }
        }

        instance.kendoTooltip({
          filter: '.saveSpreadsheet',
          position: 'top',
          content: (e: any) => {
            if (!!e && !!e.target && !!e.target[0]) {
              if (e.target[0].classList.contains('saveSpreadsheet')) {
                return t('Save Changes')
              }
            }
          },
        })
      }
    }, [data])

    useEffect(() => {
      const spreadsheet = $('#spreadsheet').data('kendoSpreadsheet')

      if (spreadsheet) {
        kendo.ui.progress(spreadsheet.element, isLoading)
      }
    }, [isLoading])

    useEffect(() => {
      if (!!offerParamDto && offerParamDto != previousOfferParamDto) {
        setIsLoading(true)
        setInitialLoadFlag(false)

        store?.getSpreadsheet(offerParamDto).then((result) => {
          // setVersion(undefined)
          setTemplateOffer(undefined)
          setIsItForExistingCustomer(false)
          setIsLoading(false)
        })
      }
    }, [offerParamDto])

    const confirmSave = () => {
      setShowConfirmSaveModal(true)
    }

    const uploadData = async () => {
      if (!offerParamDto || !offerParamDto.orgUnit || !offerParamDto.orgUnit.id || !offerParamDto.startDate || !offerParamDto.endDate) {
        toast.error(t('Unknown Error'))
        return
      }

      setIsLoading(true)

      var spreadsheetJson = $('#spreadsheet').data('kendoSpreadsheet')?.toJSON()

      if (
        !spreadsheetJson.sheets ||
        spreadsheetJson.sheets.length != totalSheetCount ||
        spreadsheetJson.sheets[summarySheetIdx].name != t(summarySheetName) ||
        spreadsheetJson.sheets[personelSheetIdx].name != t(personelSheetName)
      ) {
        toast.error(t("Can't be saved. Excel sheets are changed."))
        setIsLoading(false)
        return
      }

      const offerName = (spreadsheetJson.sheets[summarySheetIdx].rows[0].cells[1].value || '').trim()
      if (offerName == '') {
        toast.error(t('You must enter an offer name'))
        setIsLoading(false)
        return
      }

      if (offerParamDto.filterOffer?.id == undefined) {
        if (savedOfferId == undefined) {
          const tmpSavedOfferId = (
            await store!.postSpreadsheet(
              { sheets: spreadsheetJson.sheets } as ISpreadsheetDTO,
              offerParamDto.orgUnit.id,
              offerParamDto.startDate,
              offerParamDto.endDate,
              offerParamDto.isItForExistingCustomer === true
            )
          ).data
          setSavedOfferId(tmpSavedOfferId)
        } else {
          await store!.postSpreadsheet(
            { sheets: spreadsheetJson.sheets } as ISpreadsheetDTO,
            offerParamDto.orgUnit.id,
            offerParamDto.startDate,
            offerParamDto.endDate,
            offerParamDto.isItForExistingCustomer === true,
            savedOfferId
          )
        }
      } else {
        await store!.postSpreadsheet(
          { sheets: spreadsheetJson.sheets } as ISpreadsheetDTO,
          offerParamDto.orgUnit.id,
          offerParamDto.startDate,
          offerParamDto.endDate,
          offerParamDto.isItForExistingCustomer === true,
          offerParamDto?.filterOffer?.id
        )
      }

      await store!.getOffers({ skipCount: 0, maxResultCount: 5 })

      setIsLoading(false)
      toast.success(t('Successfully saved'))
    }

    const createNewOffer = () => {
      setSelectedOfferRow(undefined)
      setShowParameterPopup(true)
    }

    const detailChooseDeletionMessage = () => {
      if (!selectedOfferRow) {
        return <p>{t('Unknown Error')}</p>
      }

      return (
        <>
          <p>
            {t('Name')}: {selectedOfferRow.name}
          </p>
          <p>
            {t('Creator Name')}: {selectedOfferRow.creatorUserName}
          </p>
          <p>
            {t('Creation Time')}: {moment(selectedOfferRow.createDateTime).format('DD.MM.YYYY - HH:mm')}
          </p>
        </>
      )
    }

    const offerColumns = useMemo(
      () => [
        {
          Header: <i style={{ color: 'blue' }} className="fas fa-cog fa-2x"></i>,
          id: 'actions',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)
            return (
              <>
                {row.isReadOnly === true && (
                  <Button
                    icon={<i className="fas fa-eye"></i>}
                    tooltipText={t('Details')}
                    onClick={async () => {
                      setSelectedOfferRow(row)
                      store?.unloadSpreadsheet()
                      setCollapseActiveKey([])
                      setOfferParamDto({
                        filterOffer: row,
                        // versionDto: row.versionDto,
                        isItForExistingCustomer: row.isItForExistingCustomer,
                        orgUnit: row.orgUnit,
                        startDate: row.startDate,
                        endDate: row.endDate
                      } as IOfferParameterDto)
                    }}
                    className="btn btn-link"
                  />
                )}

                {row.isReadOnly !== true && (
                  <Button
                    icon={<i className="fas fa-pen"></i>}
                    tooltipText={t('Edit')}
                    onClick={async () => {
                      setSelectedOfferRow(row)
                      setShowParameterPopup(true)
                    }}
                    className="btn btn-link text-warning"
                  />
                )}

                {row.isReadOnly !== true && (
                  <Button
                    icon={<i className="fas fa-trash"></i>}
                    tooltipText={t('Delete')}
                    onClick={async () => {
                      setSelectedOfferRow(row)
                      setDeleteModal(true)
                    }}
                    className="btn btn-link text-danger"
                  />
                )}
              </>
            )
          },
          className: 'd-flex justify-content-center text-center border-bottom-0',
        },
        {
          Header: t('Name'),
          id: 'name',
          accessor: 'name',
          Cell: ({ row }: any) => {
            const { name } = row.values

            return <span dangerouslySetInnerHTML={{ __html: name }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        // {
        //   Header: t('Pricing'),
        //   id: 'versionDto',
        //   accessor: 'versionDto',
        //   Cell: ({ row }: any) => {
        //     const { versionDto } = row.values

        //     return <span dangerouslySetInnerHTML={{ __html: versionDto.name }}></span>
        //   },
        //   className: 'd-none d-md-table-cell',
        // },
        {
          Header: t('Organizational Unit'),
          id: 'orgUnit',
          accessor: 'orgUnit',
          Cell: ({ row }: any) => {
            const { orgUnit } = row.values

            return <span dangerouslySetInnerHTML={{ __html: orgUnit?.name ?? '-' }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Start Date'),
          id: 'startDate',
          accessor: 'startDate',
          Cell: ({ row }: any) => {
            const { startDate } = row.values

            return <span dangerouslySetInnerHTML={{ __html: !startDate ? '-' : moment(startDate).format('DD.MM.YYYY') }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('End Date'),
          id: 'endDate',
          accessor: 'endDate',
          Cell: ({ row }: any) => {
            const { endDate } = row.values

            return <span dangerouslySetInnerHTML={{ __html: !endDate ? '-' : moment(endDate).format('DD.MM.YYYY') }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Creator Name'),
          id: 'creatorUserName',
          accessor: 'creatorUserName',
          Cell: ({ row }: any) => {
            const { creatorUserName } = row.values

            return <span dangerouslySetInnerHTML={{ __html: creatorUserName }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Creation Time'),
          id: 'createDateTime',
          accessor: 'createDateTime',
          Cell: ({ row }: any) => {
            const { createDateTime } = row.values

            return <span dangerouslySetInnerHTML={{ __html: moment(createDateTime).format('DD.MM.YYYY - HH:mm') }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
      ],
      [t]
    )

    const css = `
      .k-spreadsheet-sheets-remove {
        display: none !important;
      }
      a.k-spreadsheet-sheets-bar-add.k-button.k-icon-button {
        display: none !important;
      }
      .k-disabled {
        opacity: 1;
      }
      .k-spreadsheet-sheets-bar-active {
        background: #f5e3e3;
      }
      .k-spreadsheet-disabled-mask {
        display: none;
      }
      #k-license-banner {
        display: none !important;
      }
      .k-spreadsheet-window.k-action-window.k-popup-edit-form.k-window-content > .k-edit-label,
      .k-spreadsheet-window.k-action-window.k-popup-edit-form.k-window-content > .k-edit-field {
        display: none;
      }
      .app-inner-layout__header {
        display: none;
      }`

    return (
      <>
        <style>{css}</style>

        <Collapse
          activeKey={collapseActiveKey}
          onChange={() =>
            setCollapseActiveKey((prev) => {
              if (prev.length === 1) {
                return []
              }
              return ['1']
            })
          }
        >
          <Panel
            key="1"
            header={
              t('Offers') +
              (!offerParamDto?.filterOffer?.id ? '' : ' - ' + t('Offer') + ': ' + offerParamDto?.filterOffer?.name)
            }
          >
            <div className="card-body">
              <div className="card-header">
                <div className="row">
                  {t('Offers')}{' '}
                  <div className="btn-actions-pane-right actions-icon-btn">
                    <button
                      className="btn btn-primary btn-shadow flex-direction float-right"
                      style={{ width: '70px', marginLeft: '10px', marginTop: '-5px' }}
                      onClick={createNewOffer}
                    >
                      <i className="fas fa-plus mr-2"></i>
                      {t('Add')}
                    </button>
                  </div>
                </div>
              </div>

              <br />

              <div className="row" style={{ marginBottom: '-30px', marginLeft: '0px' }}>
                {t('Offers added to a customer offer solution cannot be changed')}
              </div>

              <Table
                smallSize={true}
                columns={offerColumns}
                totalCount={offers.totalCount}
                data={offers.items}
                fetchData={(x) => store?.getOffers(x)}
                isLoading={!offers.isLoaded}
              />
            </div>
          </Panel>
        </Collapse>

        {!store?.spreadsheet.isLoaded ? <Loading /> : <div id="spreadsheet" style={{ width: '100%', height: '84vh' }}></div>}

        {deleteModal && (
          <ConfirmModal
            message={t('The record will be deleted, do you confirm?')}
            detailMessage={detailChooseDeletionMessage()}
            title={t('Warning') + '!'}
            isShow={deleteModal}
            toggleModal={toggleDeleteModal}
            onConfirm={async () => {
              await store!.deleteOffer(selectedOfferRow!.id)
              toggleDeleteModal()
            }}
          />
        )}

        {showParameterPopup && (
          <ParameterPopup
            isShow={showParameterPopup}
            toggleModal={() => setShowParameterPopup(!showParameterPopup)}
            selectedRow={selectedOfferRow}
            // setVersion={setVersion}
            setTemplateOffer={setTemplateOffer}
            setIsParameterPopupConfirmed={setIsParameterPopupConfirmed}
            setIsItForExistingCustomer={setIsItForExistingCustomer}
            setOrgUnit={setOrgUnit}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        )}

        {showConfirmSaveModal && (
          <ConfirmModal
            message={t('The data will be saved, do you confirm?')}
            detailMessage={t('Conventional Offer Save Confirm Detail Message')}
            title={t('Save')}
            isShow={showConfirmSaveModal}
            toggleModal={() => setShowConfirmSaveModal(!showConfirmSaveModal)}
            onConfirm={() => {
              setShowConfirmSaveModal(false)
              uploadData()
            }}
          />
        )}
      </>
    )
  })
)

export default Page
