import React, { useState, useEffect, FC, useRef, useMemo } from 'react'
import { inject, observer } from 'mobx-react'

import './styles.scss'
import Store from './store'

import { Button, ConfirmModal, Loading, Table } from '@/components'
import '@progress/kendo-ui'
import $ from 'jquery'
import moment from 'moment'
import { Collapse } from 'antd'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'utils/extensions'
import { Nav, NavItem, NavLink, Row } from 'react-bootstrap'
import { ConventionalOfferVersionDto } from '../conventional-offer-admin/types'
import { IIdNameDTO } from '../customer-offer-grid/types'
import { Combobox } from 'react-widgets/cjs'
import i18n from '@/utils/i18n'
import DatePicker from 'react-datepicker'
import { IStateHolidayDayDto, IStateHolidayDto } from './types'
import View from './add-state-holiday'
import AddHoliday from './add-state-holiday-day'
import IPagedRequest from '@/models/dto/fetch/IPagedRequest'

interface IPage {
  HolidaysStore?: Store
  t: any
}

const { Panel } = Collapse

const formatTL = new Intl.NumberFormat('tr-TR')

const Page: FC<IPage> = inject('HolidaysStore')(
  observer((props: IPage) => {
    const { HolidaysStore: store, t } = props

    const { stateHolidayDays } = store!

    const { stateHolidays } = store!

    const [activeTabIndex, setActiveTabIndex] = useState(0)

    const [selectedVersionRow, selectVersionRow] = useState<IStateHolidayDto>({} as IStateHolidayDto)

    const [selectedRow, setSelectedRow] = useState<IStateHolidayDayDto>({} as IStateHolidayDayDto)

    const [deleteModal, setDeleteModal] = useState(false)
    const toggleDeleteModal = () => setDeleteModal(!deleteModal)

    const [deleteDayModal, setDeleteDayModal] = useState(false)
    const toggleDeleteDayModal = () => setDeleteDayModal(!deleteDayModal)

    const [state, setState] = useState<IIdNameDTO>()

    const [year, setYear] = useState(2024)

    const [showResults, setShowResults] = useState(false)

    const [showTemplates, setShowTemplates] = useState(false)

    const [editModal, setEditModal] = useState(false)
    const toggleEditModal = () => setEditModal(!editModal)

    const [addHolidayModal, setAddHolidayModal] = useState(false)
    const toggleAddHolidayModal = () => setAddHolidayModal(!addHolidayModal)

    useEffect(() => {
      if (activeTabIndex === 1 && selectedVersionRow) {
        getHolidays()
      }
    }, [activeTabIndex, selectedVersionRow])

    useEffect(() => {
      if (activeTabIndex === 0) {
        getStateHolidays()
      }
    }, [activeTabIndex])

    const getHolidays = async (params?: IPagedRequest) => {
      let pagedRequest: IPagedRequest = {
        skipCount: 0,
        maxResultCount: 5,
      }
      if (params !== undefined) {
        pagedRequest = params
      }

      await store!.getHolidays({ ...pagedRequest }, selectedVersionRow.id)

      setShowResults(true)
    }

    const getStateHolidays = async () => {
      await store!.getStateHolidays()

      if (store!.stateHolidays.items.length > 0) {
        let currentSelection = selectedVersionRow ? store!.stateHolidays.items.find((item) => item.id === selectedVersionRow.id) : null

        if (!currentSelection) {
          currentSelection = store!.stateHolidays.items[0]
        }

        store!.stateHolidays.items = store!.stateHolidays.items.map((item) => ({
          ...item,
          isSelected: item.id === currentSelection?.id,
        }))

        selectVersionRow(currentSelection)
      }

      setShowTemplates(true)
    }

    const columns = useMemo(
      () => [
        {
          Header: <i style={{ color: 'blue' }} className="fas fa-cog fa-2x"></i>,
          id: 'actions',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)
            const { isSelected, id } = row

            return (
              <>
                <Button
                  icon={<i className="fas fa-times"></i>}
                  tooltipText={t('Remove')}
                  onClick={async () => {
                    setSelectedRow(row)
                    setDeleteDayModal(true)
                  }}
                  className="btn btn-link text-danger"
                />
              </>
            )
          },
          className: 'd-flex justify-content-center text-center align-items-center border-bottom-0',
        },
        {
          Header: t('Holiday Name'),
          id: 'name',
          canFilterable: true,
          accessor: 'name',
          className: 'd-none d-md-table-cell',
          Cell: ({ row }: any) => {
            const name = row.values.name
            return <span dangerouslySetInnerHTML={{ __html: name }}></span>
          },
        },
        {
          Header: t('Date'),
          id: 'date',
          canFilterable: true,
          accessor: 'date',
          className: 'd-none d-md-table-cell',
          Cell: ({ row }: any) => {
            const date = row.values.date
            return <span dangerouslySetInnerHTML={{ __html: date ? moment(date).format('DD.MM.YYYY') : '-' }}></span>
          },
        },
        {
          Header: t('Is Full Day?'),
          id: 'isFullDay',
          canFilterable: true,
          accessor: 'isFullDay',
          className: 'd-none d-md-table-cell',
          Cell: ({ row }: any) => {
            const isFullDay = row.values.isFullDay
            return <span dangerouslySetInnerHTML={{ __html: isFullDay }}></span>
          },
        },
      ],
      [t]
    )

    const columnsStateHolidays = useMemo(
      () => [
        {
          Header: <i style={{ color: 'blue' }} className="fas fa-cog fa-2x"></i>,
          id: 'actions',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)
            const { isSelected, id } = row

            return (
              <>
                <Button
                  icon={<i className={isSelected ? 'fas fa-check-square' : 'fas fa-square'}></i>}
                  tooltipText={t('Choose')}
                  onClick={async () => {
                    store!.stateHolidays.items = store!.stateHolidays.items.map((item) => ({
                      ...item,
                      isSelected: item.id === id,
                    }))
                    selectVersionRow(row)
                  }}
                  className="btn btn-link "
                />

                <Button
                  icon={<i className="fas fa-times"></i>}
                  tooltipText={t('Remove')}
                  onClick={() => {
                    selectVersionRow(row)
                    setDeleteModal(true)
                  }}
                  className="btn btn-link text-danger"
                />
              </>
            )
          },
          className: 'd-flex justify-content-center text-center align-items-center border-bottom-0',
        },
        {
          Header: t('State'),
          id: 'stateName',
          canFilterable: true,
          accessor: 'stateName',
          className: 'd-none d-md-table-cell',
          Cell: ({ row }: any) => {
            const stateName = row.values.stateName
            return <span dangerouslySetInnerHTML={{ __html: stateName }}></span>
          },
        },
        {
          Header: t('Year'),
          id: 'year',
          canFilterable: true,
          accessor: 'year',
          className: 'd-none d-md-table-cell',
          Cell: ({ row }: any) => {
            const year = row.values.year
            return <span dangerouslySetInnerHTML={{ __html: year }}></span>
          },
        },
        {
          Header: t('Creation Time'),
          id: 'creationDate',
          canFilterable: true,
          accessor: 'creationDate',
          className: 'd-none d-md-table-cell',
          Cell: ({ row }: any) => {
            const creationDate = row.values.creationDate
            return <span dangerouslySetInnerHTML={{ __html: creationDate ? moment(creationDate).format('DD.MM.YYYY') : '-' }}></span>
          },
        },
        {
          Header: t('Creator Personnel'),
          id: 'creatorPersonnel',
          canFilterable: true,
          accessor: 'creatorPersonnel',
          className: 'd-none d-md-table-cell',
          Cell: ({ row }: any) => {
            const creatorPersonnel = row.values.creatorPersonnel
            return <span dangerouslySetInnerHTML={{ __html: creatorPersonnel }}></span>
          },
        },
      ],
      [t, stateHolidays.items]
    )

    return (
      <>
        <div style={{ padding: '0.5rem 1.5rem' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            State:{' '}
            <div className="text-danger" style={{ marginLeft: 5 }}>
              {' '}
              {selectedVersionRow.stateName}
            </div>
          </div>
          <br />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            Year:{' '}
            <div className="text-danger" style={{ marginLeft: 5 }}>
              {selectedVersionRow.year}
            </div>
          </div>
        </div>
        <div className="card-tabs">
          <div className="card-header d-flex align-items-center justify-content-between">
            <Nav className="w-auto">
              <NavItem>
                <NavLink
                  onClick={() => {
                    setActiveTabIndex(0)
                  }}
                  active={activeTabIndex === 0}
                >
                  {t('States and Years')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => {
                    setActiveTabIndex(1)
                  }}
                  active={activeTabIndex === 1}
                >
                  {t('Holidays')}
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>

        {editModal && <View data={selectedVersionRow} isShow={editModal} toggleModal={toggleEditModal} />}

        {addHolidayModal && <AddHoliday data={selectedVersionRow} isShow={addHolidayModal} toggleModal={toggleAddHolidayModal} />}

        <div className="card-body">
          {activeTabIndex === 0 && showTemplates && (
            <>
              <div className="p-3">
                <div className="card-body">
                  <div className="card-header">
                    <div className="row">
                      {t('States and Years')}{' '}
                      <div className="btn-actions-pane-right actions-icon-btn">
                        <button
                          className="btn btn-primary btn-shadow flex-direction float-right"
                          style={{ width: '70px', marginLeft: '10px', marginTop: '-5px' }}
                          onClick={async () => {
                            setEditModal(true)
                          }}
                        >
                          <i className="fas fa-plus mr-2"></i>
                          {t('Add')}
                        </button>
                      </div>
                    </div>
                  </div>
                  <Table
                    columns={columnsStateHolidays}
                    showHeaderSearchInput={false}
                    totalCount={stateHolidays.totalCount}
                    data={stateHolidays.items}
                    fetchData={getStateHolidays}
                    isLoading={!stateHolidays.isLoaded}
                    showNavigation={false}
                  />
                </div>
              </div>
            </>
          )}

          {activeTabIndex === 1 && showResults && (
            <>
              <div className="p-3">
                <div className="card-body">
                  <div className="card-header">
                    <div className="row">
                      {t('Holidays')}{' '}
                      <div className="btn-actions-pane-right actions-icon-btn">
                        <button
                          className="btn btn-primary btn-shadow flex-direction float-right"
                          style={{ width: '70px', marginLeft: '10px', marginTop: '-5px' }}
                          onClick={async () => {
                            setAddHolidayModal(true)
                          }}
                        >
                          <i className="fas fa-plus mr-2"></i>
                          {t('Add')}
                        </button>
                      </div>
                    </div>
                  </div>
                  <Table
                    columns={columns}
                    showHeaderSearchInput={false}
                    totalCount={stateHolidayDays.totalCount}
                    data={stateHolidayDays.items}
                    fetchData={getHolidays}
                    isLoading={!stateHolidayDays.isLoaded}
                    filterable={true}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {deleteModal && (
          <ConfirmModal
            message={t('This state holiday will be deleted, do you confirm?')}
            title={t('Warning') + '!'}
            isShow={deleteModal}
            toggleModal={toggleDeleteModal}
            onConfirm={async () => {
              await store!.deleteStateHoliday(selectedVersionRow)
              toggleDeleteModal()
            }}
          />
        )}

        {deleteDayModal && (
          <ConfirmModal
            message={t('This day will be deleted. Do you confirm?')}
            title={t('Warning') + '!'}
            isShow={deleteDayModal}
            toggleModal={toggleDeleteDayModal}
            onConfirm={async () => {
              await store!.deleteDay(selectedRow)
              toggleDeleteDayModal()
            }}
          />
        )}
      </>
    )
  })
)

export default Page
