import React, { FC, useState, useRef, MutableRefObject, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button } from '@/components'
import * as _ from 'lodash'
import Store from './store'
import { Combobox } from 'react-widgets/cjs'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { IAddStateHolidayDto } from './types'
import DatePicker from 'react-datepicker'
import i18n from '@/utils/i18n'

interface IView {
  HolidaysStore?: Store
  data?: IAddStateHolidayDto
  isShow: boolean
  toggleModal: any
}

const View: FC<IView> = inject('HolidaysStore')(
  observer((props: IView) => {
    const { HolidaysStore: store, data, isShow, toggleModal } = props
    const { t } = useTranslation()

    const { states } = store!

    const { baseStateHolidays } = store!

    const { register, handleSubmit, errors } = useForm()

    const [selectedStateId, setSelectedStateId] = useState(0)

    const [year, setYear] = useState(2024)

    const [baseStateHolidayId, setBaseStateHolidayId] = useState(0)

    useEffect(() => {
      store?.getStates()
    }, [])

    useEffect(() => {
      store?.getBaseStateHolidays(year)
    }, [year])

    const submit = async () => {
      if (year == null) {
        toast.error(t('Please type name'))
        return
      }

      const dto: IAddStateHolidayDto = {
        year: year,
        stateId: selectedStateId,
        baseStateHolidayId: baseStateHolidayId,
      }

      await store!.addStateHoliday(dto)
      toggleModal()
    }

    return (
      <Modal isOpen={isShow} toggle={toggleModal} className="modal-md">
        <ModalHeader toggle={toggleModal}>{t('Add New State Holiday')}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="form-group w-100">
                <label htmlFor="state" className="font-weight-bold">
                  {t('State')}
                </label>
                <Combobox
                  id="state"
                  filter="contains"
                  data={states}
                  textField="name"
                  onSelect={(val: any) => {
                    setSelectedStateId(val.id)
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-12">
              <div className="form-group w-100">
                <label htmlFor="year" className="font-weight-bold">
                  {t('Year')}
                </label>
                <DatePicker
                  dateFormat="yyyy"
                  showYearPicker
                  className={'form-control mb-0'}
                  locale={i18n.language}
                  id="year"
                  name="year"
                  selected={new Date(year, 0, 1)}
                  onChange={(date: Date) => setYear(date.getFullYear())}
                  autoComplete="off"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-12">
              <div className="form-group w-100">
                <label htmlFor="baseStateHolidays" className="font-weight-bold">
                  {t('Base State')}
                </label>
                <Combobox
                  id="baseStateHolidays"
                  filter="contains"
                  data={[{ id: 0, stateName: t('None') }, ...baseStateHolidays]}
                  textField="stateName"
                  onSelect={(val: any) => {
                    setBaseStateHolidayId(val.id)
                  }}
                />
              </div>
              <label htmlFor="baseStateHolidays" className="">
                <strong className="text-danger">* </strong>
                {t('If you select base state, the holidays of that base state will be copied to the newly added state')}
              </label>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-light mr-auto" onClick={toggleModal} icon={<i className="fas fa-times mr-2"></i>} text={t('Close')} />

          <Button
            className="btn btn-success float-right"
            onClick={handleSubmit(async (data: any) => await submit())}
            icon={<i className="far fa-save mr-2"></i>}
            text={t('Save')}
            showConfirm={true}
          />
        </ModalFooter>
      </Modal>
    )
  })
)

export default View
