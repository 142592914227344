import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button, Table } from '@/components'
import * as _ from 'lodash'
import { useForm } from 'react-hook-form'
import Store from './store'
import { Combobox } from 'react-widgets/cjs'
import { IOfferDto } from './types'
import { toast } from 'react-toastify'
import moment from 'moment'
import ReactDatePicker from 'react-datepicker'
import cx from 'classnames'
import { IOrgUnitDto } from '../conventional-offer-admin/types'

interface IView {
  ConventionalOfferStore?: Store
  isShow: boolean
  toggleModal: any
  selectedRow?: IOfferDto
  setTemplateOffer: any
  setIsParameterPopupConfirmed: any
  setIsItForExistingCustomer: any
  setOrgUnit: any
  setStartDate: any
  setEndDate: any
}

const View: FC<IView> = inject('ConventionalOfferStore')(
  observer((props: IView) => {
    const {
      ConventionalOfferStore: store,
      isShow,
      toggleModal,
      selectedRow,
      setTemplateOffer: setTemplateOfferInIndex,
      setIsParameterPopupConfirmed,
      setIsItForExistingCustomer: setIsItForExistingCustomerInIndex,
      setOrgUnit: setOrgUnitInIndex,
      setStartDate: setStartDateInIndex,
      setEndDate: setEndDateInIndex
    } = props
    const { t } = useTranslation()

    const { handleSubmit } = useForm()

    const [templateOffer, setTemplateOffer] = useState<IOfferDto>()
    const [offerList, setOfferList] = useState<IOfferDto[]>()
    const [isLoading, setIsLoading] = useState(true)
    const [isItForExistingCustomer, setIsItForExistingCustomer] = useState(false)
    const [orgUnits, setOrgUnits] = useState<IOrgUnitDto[]>()
    const [orgUnit, setOrgUnit] = useState<IOrgUnitDto>()
    const [startDate, setStartDate] = useState<Date>()
    const [endDate, setEndDate] = useState<Date>()

    useEffect(() => {
      Promise.all([
        store!.getOffersAsList(),
        store!.getOrgUnits()
      ]).then((resArr) => {
        if (!selectedRow) {
          setOfferList(resArr[0])
        }
        else {
          setIsItForExistingCustomer(selectedRow.isItForExistingCustomer === true)
          setOrgUnit(selectedRow.orgUnit)
          setStartDate(selectedRow.startDate)
          setEndDate(selectedRow.endDate)
        }
        setOrgUnits(resArr[1])
        setIsLoading(false)
      })
    }, [])

    const submit = async () => {
      if (!orgUnit || !orgUnit.id) {
        toast.error(t('Please select organizational unit'))
        return
      }

      if (!startDate || !endDate) {
        toast.error(t('Please select start and end dates'))
        return
      }

      if (startDate > endDate) {
        toast.error("Start date must be before the end date")
        return
      }

      const isThisIntervalAvailableForOffer = await store!.getCheckForPricingInInterval(orgUnit!.id, moment(startDate).format('DD-MM-YYYY'), moment(endDate).format('DD-MM-YYYY'))

      if (!isThisIntervalAvailableForOffer) {
        toast.error(t('There are days in the selected time period where pricing has not been defined. Please contact the admin.'))
        return
      }

      setOrgUnitInIndex(orgUnit)

      setStartDateInIndex(startDate)

      setEndDateInIndex(endDate)

      setTemplateOfferInIndex(templateOffer)

      setIsItForExistingCustomerInIndex(isItForExistingCustomer)

      setIsParameterPopupConfirmed(true)

      toggleModal()
    }

    const modalHeaderText = !selectedRow ? t('New Offer') : t('Edit Offer')

    return (
      <Modal isOpen={isShow} toggle={toggleModal} className="modal-lg">
        <ModalHeader toggle={toggleModal}>{t(modalHeaderText)}</ModalHeader>
        <ModalBody>
          <div className="d-flex" style={{ marginLeft: '20px' }}>
            <div className="form-row py-3">{t('Please select the customer type')}</div>
          </div>

          <div className="d-flex" style={{ marginBottom: '10px', marginLeft: '0px!important' }}>
            <div className="custom-control custom-radio px-5">
              <input
                type="radio"
                className="custom-control-input"
                id="typeRadio1"
                name="type"
                value="1"
                defaultChecked={isItForExistingCustomer}
                checked={isItForExistingCustomer}
                onChange={(value) => {
                  if (value.target.checked) {
                    setIsItForExistingCustomer(true)
                  }
                }}
              />
              <label className="custom-control-label" htmlFor="typeRadio1">
                {t('Existing Customer')}
              </label>
            </div>
            <div className="custom-control custom-radio px-5">
              <input
                type="radio"
                className="custom-control-input"
                id="typeRadio0"
                name="type"
                value="0"
                checked={!isItForExistingCustomer}
                onChange={(value) => {
                  if (value.target.checked) {
                    setIsItForExistingCustomer(false)
                  }
                }}
              />
              <label className="custom-control-label" htmlFor="typeRadio0">
                {t('Candidate Customer')}
              </label>
            </div>
          </div>

          <div className="d-flex" style={{ marginLeft: '20px' }}>
            <div className="form-row py-3">
              <div className="form-group" style={{ width: '300px' }}>
                <label htmlFor="orgUnit" className="">
                  {t('Organizational Unit')}
                </label>

                <Combobox
                  filter="contains"
                  data={orgUnits || []}
                  value={orgUnit?.name}
                  textField="name"
                  onSelect={(val: any) => {
                    setOrgUnit(val)
                  }}
                />
              </div>
            </div>
          </div>

          <div className="d-flex" style={{ marginLeft: '20px' }}>
            <div className="form-row py-3">
              <div className="form-group" style={{ width: '300px' }}>
                <label htmlFor="startDate" className="">
                  {t('Start Date')}
                </label>

                <ReactDatePicker
                  dateFormat={moment().format('DD-MM-YYYY')}
                  locale={moment.locale()}
                  className={cx('form-control')}
                  id="startDate"
                  name="startDate"
                  value={startDate ? moment(startDate).format('DD-MM-YYYY') : ''}
                  selected={startDate && moment(startDate).toDate()}
                  onChange={(date: Date) => {
                    if (date) {
                      setStartDate(moment(date).toDate())
                    }
                  }}
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="form-row py-3" style={{ marginLeft: '20px' }}>
              <div className="form-group" style={{ width: '300px' }}>
                <label htmlFor="endDate" className="">
                  {t('End Date')}
                </label>

                <ReactDatePicker
                  dateFormat={moment().format('DD-MM-YYYY')}
                  locale={moment.locale()}
                  className={cx('form-control')}
                  id="endDate"
                  name="endDate"
                  value={endDate ? moment(endDate).format('DD-MM-YYYY') : ''}
                  selected={endDate && moment(endDate).toDate()}
                  onChange={(date: Date) => {
                    if (date) {
                      setEndDate(moment(date).toDate())
                    }
                  }}
                  autoComplete="off"
                />
              </div>
            </div>
          </div>

          {!selectedRow && (
            <>
              <div className="d-flex" style={{ marginLeft: '20px' }}>
                <div className="form-row py-3">{t('If you want to use a previous offer as a template, please choose an offer. If you want to create an offer from scratch, you may continue without choosing.')}</div>
              </div>
              <div className="d-flex" style={{ marginLeft: '20px' }}>
                <div className="form-row py-3">
                  <div className="form-group" style={{ width: '300px' }}>
                    <label htmlFor="offer" className="">
                      {t('Offer')}
                    </label>

                    <Combobox
                      data={offerList || []}
                      textField="listName"
                      dataKey="listName"
                      name="offer"
                      id="id"
                      onChange={(selected: any) => {
                        setTemplateOffer(selected)
                      }}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-light mr-auto" onClick={toggleModal} icon={<i className="fas fa-times mr-2"></i>} text={t('Close')} />

          <Button
            className="btn btn-secondary mr-right"
            onClick={handleSubmit(async (data: any) => await submit())}
            icon={<i className="fas fa-arrow-right mr-2"></i>}
            text={t('Continue')}
            disabled={isLoading}
          />
        </ModalFooter>
      </Modal>
    )
  })
)

export default View
