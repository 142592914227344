import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from 'reactstrap'
import { Button, Loading, Table } from '@/components'
import { toast } from 'react-toastify'
import { ConventionalOfferVersionDto, IOrgUnitDto } from './types'
import Store from './store'
import { Combobox } from 'react-widgets/cjs'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import ReactDatePicker from 'react-datepicker'
import cx from 'classnames'

interface INewVersionPopupProps {
  ConventionalOfferVersionStore?: Store
  toggleModal: any
  selectedRow?: ConventionalOfferVersionDto
}

const NewVersionPopup: FC<INewVersionPopupProps> = inject('ConventionalOfferVersionStore')(
  observer((props: INewVersionPopupProps) => {
    const { ConventionalOfferVersionStore: store, toggleModal, selectedRow } = props
    const { t } = useTranslation()

    const history = useHistory()

    const [isLoading, setIsLoading] = useState(true)
    const [versionList, setVersionList] = useState<ConventionalOfferVersionDto[]>()
    const [templateVersion, setTemplateVersion] = useState<ConventionalOfferVersionDto>()
    const [orgUnits, setOrgUnits] = useState<IOrgUnitDto[]>()

    const [newVersion, setNewVersion] = useState<ConventionalOfferVersionDto>({
      id: selectedRow ? selectedRow.id : undefined,
      name: selectedRow ? selectedRow.name : undefined,
      explanation: selectedRow ? selectedRow.explanation : undefined,
      orgUnit: selectedRow ? selectedRow.orgUnit : undefined,
      startDate: selectedRow ? selectedRow.startDate : undefined,
      endDate: selectedRow ? selectedRow.endDate : undefined,
    } as ConventionalOfferVersionDto)

    const [showTemplatePricingTooltip, setShowTemplatePricingTooltip] = useState(false)

    useEffect(() => {
      Promise.all([
        store!.getVersionsAsList(),
        store!.getOrgUnits()
      ]).then((resArr) => {
        setVersionList(resArr[0])
        setOrgUnits(resArr[1])
        setIsLoading(false)
      })
    }, [])

    const save = async () => {
      if ((newVersion.name || '').trim() == ''
        || (newVersion.explanation || '').trim() == ''
        || !newVersion.orgUnit || !newVersion.orgUnit.id
        || !newVersion.startDate || !newVersion.endDate
        || (!selectedRow && !templateVersion)) {
        toast.error(t('Please fill in all fields'))
        return
      }

      if (newVersion.startDate > newVersion.endDate) {
        toast.error("Start date must be before the end date")
        return
      }

      try {
        const versionId = await store!.save({
          ...newVersion,
          startDate: undefined,
          startDateStr: moment(newVersion.startDate).format('DD-MM-YYYY'),
          endDate: undefined,
          endDateStr: moment(newVersion.endDate).format('DD-MM-YYYY')
        }, templateVersion?.id)
        history.push(`/digital-offer/conventional-offer-admin/parameters-of-version/${versionId}`)
      } catch (err) {
        // console.log(err)
      }

      toggleModal()
    }

    const isLocked = !!selectedRow?.lockTime

    const modalHeaderText = !selectedRow ? 'New Version' : 'Edit Version'

    return (
      <>
        <Modal isOpen={true} toggle={toggleModal} className="modal-lg">
          <ModalHeader toggle={toggleModal}>{t(modalHeaderText)}</ModalHeader>
          {isLoading ? (
            <ModalBody>
              <Loading />
            </ModalBody>
          ) : (
            <ModalBody>
              {isLocked && <div className="row" style={{ marginBottom: '10px' }}>
                <div className="col-md-12" style={{ display: 'flex' }}>
                  <span style={{ color: '#D02138' }}>
                    {t('Since the pricing version is locked, only the end date can be changed.')}
                  </span>
                </div>
              </div>}

              <div className="row" style={{ marginBottom: '10px' }}>
                <div className="col-md-12" style={{ display: 'flex' }}>
                  <div className="col-md-4" style={{ margin: 'auto' }}>
                    {t('Name')} <strong className="text-danger">*</strong>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      className="form-control py-4"
                      value={newVersion.name}
                      onChange={(event) => {
                        setNewVersion({
                          ...newVersion,
                          name: event.target.value,
                        } as ConventionalOfferVersionDto)
                      }}
                      disabled={isLocked}
                    />
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginBottom: '10px' }}>
                <div className="col-md-12" style={{ display: 'flex' }}>
                  <div className="col-md-4" style={{ margin: 'auto' }}>
                    {t('Explanation')} <strong className="text-danger">*</strong>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      className="form-control py-4"
                      value={newVersion.explanation}
                      onChange={(event) => {
                        setNewVersion({
                          ...newVersion,
                          explanation: event.target.value,
                        } as ConventionalOfferVersionDto)
                      }}
                      disabled={isLocked}
                    />
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginBottom: '10px' }}>
                <div className="col-md-12" style={{ display: 'flex' }}>
                  <div className="col-md-4" style={{ margin: 'auto' }}>
                    {t('Organizational Unit')}
                    <strong className="text-danger">{' * '}</strong>
                  </div>
                  <div className="col-md-8">
                    <Combobox
                      filter="contains"
                      data={orgUnits || []}
                      value={newVersion.orgUnit?.name}
                      textField="name"
                      onSelect={(val: any) => {
                        setNewVersion({
                          ...newVersion,
                          orgUnit: { ...val },
                        } as ConventionalOfferVersionDto)
                      }}
                      disabled={isLocked}
                    />
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginBottom: '10px' }}>
                <div className="col-md-12" style={{ display: 'flex' }}>
                  <div className="col-md-4" style={{ margin: 'auto' }}>
                    {t('Start Date')}
                    <strong className="text-danger">{' * '}</strong>
                  </div>
                  <div className="col-md-8">
                    <ReactDatePicker
                      dateFormat={moment().format('DD-MM-YYYY')}
                      locale={moment.locale()}
                      className={cx('form-control')}
                      id="startDate"
                      name="startDate"
                      value={newVersion.startDate ? moment(newVersion.startDate).format('DD-MM-YYYY') : ''}
                      selected={newVersion.startDate && moment(newVersion.startDate).toDate()}
                      onChange={(date: Date) => {
                        if (date) {
                          setNewVersion({
                            ...newVersion,
                            startDate: moment(date).toDate(),
                          } as ConventionalOfferVersionDto)
                        }
                      }}
                      autoComplete="off"
                      disabled={isLocked}
                    />
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginBottom: '10px' }}>
                <div className="col-md-12" style={{ display: 'flex' }}>
                  <div className="col-md-4" style={{ margin: 'auto' }}>
                    {t('End Date')}
                    <strong className="text-danger">{' * '}</strong>
                  </div>
                  <div className="col-md-8">
                    <ReactDatePicker
                      dateFormat={moment().format('DD-MM-YYYY')}
                      locale={moment.locale()}
                      className={cx('form-control')}
                      id="endDate"
                      name="endDate"
                      value={newVersion.endDate ? moment(newVersion.endDate).format('DD-MM-YYYY') : ''}
                      selected={newVersion.endDate && moment(newVersion.endDate).toDate()}
                      onChange={(date: Date) => {
                        if (date) {
                          setNewVersion({
                            ...newVersion,
                            endDate: moment(date).toDate(),
                          } as ConventionalOfferVersionDto)
                        }
                      }}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>

              {!selectedRow && (
                <div className="row" style={{ marginBottom: '10px' }}>
                  <div className="col-md-12" style={{ display: 'flex' }}>
                    <div className="col-md-4" style={{ margin: 'auto' }}>
                      {t('Template Pricing')}
                      <strong className="text-danger">{' * '}</strong>
                      <i id="idTemplatePricingTooltip" className="fas fa-circle-info mr-2"></i>
                      <Tooltip
                        placement={'top-start'}
                        isOpen={showTemplatePricingTooltip}
                        target={'idTemplatePricingTooltip'}
                        toggle={() => setShowTemplatePricingTooltip(!showTemplatePricingTooltip)}
                      >
                        {t('Only pricing versions that are locked and in use are shown')}
                      </Tooltip>
                    </div>
                    <div className="col-md-8">
                      <Combobox
                        filter="contains"
                        data={versionList || []}
                        value={templateVersion?.listName}
                        textField="listName"
                        onSelect={(val: any) => {
                          setTemplateVersion({ ...val })
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </ModalBody>
          )}
          <ModalFooter>
            <Button className="btn btn-light mr-auto" onClick={toggleModal} icon={<i className="fas fa-times mr-2"></i>} text={t('Close')} />

            <Button
              className="btn btn-success float-right"
              onClick={save}
              icon={<i className={'far fa-save mr-2'}></i>}
              text={t('Save')}
              showConfirm={false}
              disabled={isLoading}
            />
          </ModalFooter>
        </Modal>
      </>
    )
  })
)

export default NewVersionPopup
