import React, { useState, useEffect, FC } from 'react'
import { observer, inject } from 'mobx-react'
import * as _ from 'lodash'

import ConventionalOfferCovid19ProtectiveEquipment from '../covid19-protective-equipment'
import ConventionalOfferEquipment from '../equipment'
import ConventionalOfferGrossWage from '../gross-wage'
import ConventionalOfferInsurance from '../insurance'
import ConventionalOfferOutfit from '../outfit'
import ConventionalOfferPaidLeaveType from '../paid-leave-type'
import ConventionalOfferPaymentInLieuOfNoticeDuration from '../payment-in-lieu-of-notice-duration'
import ConventionalOfferPaymentStampTax from '../payment-stamp-tax'
import ConventionalOfferProjectManagerEquipment from '../project-manager-equipment'
import ConventionalOfferShiftOvertime from '../shift-overtime'
import ConventionalOfferPaidLeaveCompensation from '../paid-leave-compensation'
import ConventionalOfferWage from '../wage'
import ConventionalOfferWorkHealthAndSafety from '../work-health-and-safety'
import ConventionalOfferFormulaConstant from '../formula-constant'
import ConventionalOfferWorkingRetiredEmployerShare from '../working-retired-employer-share'
import { useHistory, useParams } from 'react-router-dom'
import Store from '../store'
import { Loading } from '@/components'
import { ConventionalOfferVersionDto } from '../types'
import moment from 'moment'

interface IPage {
  ConventionalOfferVersionStore?: Store
  t: any
}

const Page: FC<IPage> = inject('ConventionalOfferVersionStore')(
  observer((props: IPage) => {
    const { ConventionalOfferVersionStore: store, t } = props

    const { versionId }: any = useParams()

    const history = useHistory()

    const [isLoading, setIsLoading] = useState(true)
    const [version, setVersion] = useState<ConventionalOfferVersionDto>()

    useEffect(() => {
      store!.getSingleVersion(versionId).then((res) => {
        setVersion(res)
        setIsLoading(false)
      })
    }, [])

    if (versionId == null || versionId < 1) {
      return <div>{t('Unknown Error')}</div>
    }

    if (isLoading) {
      return <Loading />
    }

    return (
      <>
        <div className="card-header">
          <div>
            {version?.name + ' - '
            + t('Unit') + ': ' + version?.orgUnit?.name + ' - '
            + t('Validity Period') + ': ' + moment(version?.startDate).format('DD.MM.YYYY') + ' / ' + moment(version?.endDate).format('DD.MM.YYYY')}
          </div>
          <div className="btn-actions-pane-right actions-icon-btn">
            <button
              className="btn btn-danger btn-shadow flex-direction float-right"
              onClick={() => history.push(`/digital-offer/conventional-offer-admin`)}
            >
              &laquo; {t('Return To Versions')}
            </button>
          </div>
        </div>

        <div className="d-flex justify-content">
          <div className="col-md-12">
            <ConventionalOfferWage versionId={versionId} disabled={!version || version.lockTime != undefined} />
          </div>
        </div>

        {/* <div className="d-flex justify-content">
          <div className="col-md-12">
            <ConventionalOfferFormulaConstant versionId={versionId} disabled={!version || version.lockTime != undefined} />
          </div>
        </div>

        <div className="d-flex justify-content">
          <div className="col-md-12">
            <ConventionalOfferWorkingRetiredEmployerShare versionId={versionId} disabled={!version || version.lockTime != undefined} />
          </div>
        </div>

        <div className="d-flex justify-content">
          <div className="col-md-12">
            <ConventionalOfferCovid19ProtectiveEquipment versionId={versionId} disabled={!version || version.lockTime != undefined} />
          </div>
        </div>

        <div className="d-flex justify-content">
          <div className="col-md-12">
            <ConventionalOfferEquipment versionId={versionId} disabled={!version || version.lockTime != undefined} />
          </div>
        </div>

        <div className="d-flex justify-content">
          <div className="col-md-12">
            <ConventionalOfferGrossWage versionId={versionId} disabled={!version || version.lockTime != undefined} />
          </div>
        </div>

        <div className="d-flex justify-content">
          <div className="col-md-12">
            <ConventionalOfferInsurance versionId={versionId} disabled={!version || version.lockTime != undefined} />
          </div>
        </div>

        <div className="d-flex justify-content">
          <div className="col-md-12">
            <ConventionalOfferOutfit versionId={versionId} disabled={!version || version.lockTime != undefined} />
          </div>
        </div>

        <div className="d-flex justify-content">
          <div className="col-md-12">
            <ConventionalOfferPaidLeaveType versionId={versionId} disabled={!version || version.lockTime != undefined} />
          </div>
        </div>

        <div className="d-flex justify-content">
          <div className="col-md-12">
            <ConventionalOfferPaidLeaveCompensation versionId={versionId} disabled={!version || version.lockTime != undefined} />
          </div>
        </div>

        <div className="d-flex justify-content">
          <div className="col-md-12">
            <ConventionalOfferPaymentInLieuOfNoticeDuration versionId={versionId} disabled={!version || version.lockTime != undefined} />
          </div>
        </div>

        <div className="d-flex justify-content">
          <div className="col-md-12">
            <ConventionalOfferPaymentStampTax versionId={versionId} disabled={!version || version.lockTime != undefined} />
          </div>
        </div>

        <div className="d-flex justify-content">
          <div className="col-md-12">
            <ConventionalOfferProjectManagerEquipment versionId={versionId} disabled={!version || version.lockTime != undefined} />
          </div>
        </div>

        <div className="d-flex justify-content">
          <div className="col-md-12">
            <ConventionalOfferShiftOvertime versionId={versionId} disabled={!version || version.lockTime != undefined} />
          </div>
        </div>

        <div className="d-flex justify-content">
          <div className="col-md-12">
            <ConventionalOfferWorkHealthAndSafety versionId={versionId} disabled={!version || version.lockTime != undefined} />
          </div>
        </div> */}
      </>
    )
  })
)

export default Page
